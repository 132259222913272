import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { get_track_list, range } from "../../../reports/common";
import { GQLReport, GQLTag, GQLTier, GQLTrack } from "../../../schemas/schema";
import { Panel } from "../../components";
import "./ProjectScope.scss";

export class ProjectScope extends React.Component<{ report: GQLReport, tracks: GQLTrack[], tiers: GQLTier[] }> {
  render() {
    // @ts-expect-error
    const checkIcon = <FontAwesomeIcon icon={faCheck} color={"green"} />;
    const { report, tracks } = this.props;
    const all_tracks = get_track_list(report, tracks);
    const all_add_ons: GQLTag[] = [];
    for (const t of all_tracks) {
      if (!t.add_ons_tags) {
        continue;
      }
      t.add_ons_tags.forEach((tag) => {
        all_add_ons.push(tag);
      });
    }
    const { tiers } = this.props;

    return (
      <Panel title={"Project Scope"} className={"project-scope"}>
        <table className="report-detail-table project-scope-table">
          <tbody>
            <tr className="title-row">
              <th className={"track"}>Track</th>
              {tiers.map((tier) => (
                <th className="tier" key={tier.id}>{tier.name}</th>
              ))}
            </tr>
            {all_tracks.map((rt) => (
              <tr key={rt.id}>
                <td>{rt.name}</td>
                {tiers.map((_, i) => (
                  <td style={{ textAlign: "center" }} key={i}>
                    {rt.tier && parseInt(rt.tier.id) == i && checkIcon}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {all_add_ons.length > 0 && (
          <table className="report-detail-table project-scope-table modules-table">
            <tbody>
              <tr className={"title-row"}>
                <th className={"row-module"}>Module</th>
                <th className={"selected"}>Selected</th>
              </tr>

              {all_add_ons.map((add_on) => (
                <tr key={add_on.name}>
                  <td>{add_on.description ? add_on.description : add_on.name}</td>
                  <td style={{ textAlign: "center" }}>{checkIcon}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Panel>
    );
  }
}
