import moment from "moment";
import React from "react";
import { firstBy } from "thenby";
import { GQLProject } from "../../../schemas/schema";

export class DashboardProjectTable extends React.Component<{ projects: GQLProject[] }> {
  state = {
    sortColumn: null,
    sortAsc: false
  };

  get defaultSortColumn() {
    return "Date";
  }

  get sortColumn() {
    return this.state.sortColumn || this.defaultSortColumn;
  }

  get columns() {
    return ["Company", "Project", "Date", "Score"];
  }

  get _sortIndex() {
    return this.columns.indexOf(this.sortColumn);
  }

  get data() {
    return this.props.projects
      .filter((x) => x.latest_report)
      .map(({ id, name, client, company, latest_report }) => [
        {
          value: company.name,
          content: <a href={`/clients/${client.id}/reports/${latest_report.id}`}>{client.name}</a>
        },
        {
          value: name,
          content: <a href={`/clients/${client.id}/reports/${latest_report.id}`}>{name}</a>
        },
        {
          value: moment(latest_report.published_at).unix(),
          content: moment(latest_report.published_at).format("MM/DD/YYYY")
        },
        {
          value: latest_report.cached_ccia?.score?.value,
          content: <h1 className={`outline ${latest_report.cached_ccia?.score?.color}`}>{latest_report.cached_ccia?.score?.value}</h1>
        }
      ])
      .sort(firstBy((x) => x[this._sortIndex].value, this.state.sortAsc ? 1 : -1));
  }

  setSort = (col) => {
    let sortAsc = false;
    if (this.sortColumn == col) {
      sortAsc = !sortAsc;
    }
    this.setState({ sortColumn: col, sortAsc });
  };

  render() {
    return (
      <table className="projects-table sortable-mimic" id="project-table">
        <thead>
          <tr className="title-row">
            {this.columns.map((c, i) => (
              <th
                key={c}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.setSort(c);
                }}
              >
                {c}
                {i == this._sortIndex && <span className={`sign arrow ${this.state.sortAsc ? "up" : "down"}`} />}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.data.map((el, i) => (
            <tr key={i}>
              {el.map((td) => (
                <td data-value={td.value}>{td.content}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
