import cn from "classnames";
import React from "react";
import { MarkdownWrapper } from "../../../reports/common";
import { MATURITY_LEVELS, RELEVANCE_LEVELS } from "../../../reports/constants";
import { GQLReportIndicator } from "../../../schemas/schema";
import { ExpandableComponent } from "../../components";

export class ReportIndicatorNotes extends ExpandableComponent<{ ri: GQLReportIndicator, i: number }> {
  render() {
    const { ri, i } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <tr className="indicators-row" key={ri.id} data-testid="indicator-row">
          <td>{i + 1}</td>
          <td data-testid="indicator-description-column">{ri.indicator!.description}</td>
          <td>{RELEVANCE_LEVELS[ri.business_relevance! - 1].toUpperCase()}</td>
          <td className={cn("score", ri.confidence_score!.color)}>{MATURITY_LEVELS[ri.confidence_score!.value!]}</td>
          <td onClick={this.toggleExpanded} className={cn("td-scoring-notes arrow", { up: expanded })} />
        </tr>
        {expanded && (
          <tr className="indicators-notes-row active">
            <td className="scoring-notes-data" colSpan={5}>
              <section className="scoring-notes active">
                <h6>Assessor's Scoring Notes</h6>
                <article>
                  <MarkdownWrapper>{ri.notes!}</MarkdownWrapper>
                  <MarkdownWrapper>{ri.additional_notes!}</MarkdownWrapper>
                </article>
              </section>
            </td>
          </tr>
        )}
      </>
    );
  }
}
