import React from "react";
import { GQLReport, GQLTrackCcia } from "../../schemas/schema";
import { ReportTrackRow } from "./components";

interface Props {
  report: GQLReport
  ccia_tracks: GQLTrackCcia[]
}

export const TechnicalIndicators = ({ report, ccia_tracks }: Props) => (
  <>
    <section className="section module full mt2 mb2">
      <header className="small">
        <h6>Track Details</h6>
      </header>
      {report.report_tracks!.map((rt, i) => (
        <ReportTrackRow
          key={rt.id}
          obj={rt}
          report={report}
          index={i}
          score={ccia_tracks.find((x) => x.rtid == rt.id)?.score ?? null}
        />
      ))}
    </section>
  </>
);
