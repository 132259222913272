import React from "react";
import { MarkdownWrapper } from "../../../reports/common";
import { GQLReportView } from "../../../schemas/schema";
import { Panel } from "../../components";
import LegendTooltip from "../../LegendTooltip";
import { techDetailsHelpText } from "./constants";
import { ExpandedTrackRow } from "./ExpandedTrackRow";
import { TechindicatorTracksTable } from "./TechindicatorTracksTable";

export class ExpandedTrackView extends ExpandedTrackRow {
  get no_industry_data() {
    return true;
  }

  protected getTechindicatorDetails() {
    const { obj } = this.props;

    return (
      <Panel
        title="Techindicator™ Details"
        helpComponent={
          <LegendTooltip text={techDetailsHelpText} colors={["red", "orange", "yellow", "lightgreen", "green"]} />
        }
      >
        <TechindicatorTracksTable tracks={(obj as GQLReportView).tracks!} />
      </Panel>
    );
  }

  renderSummary() {
    const { obj } = this.props;
    const view = obj as GQLReportView;

    return (
      <>
        <div className="track-chart-panel">
          <div className="track-score-details">
            <div className="markdown">
              <MarkdownWrapper>{view.description}</MarkdownWrapper>
            </div>
          </div>
        </div>
        <Panel title={`View Summary - ${obj.name}`}>
          <div className="panel-text">
            <MarkdownWrapper>{view.summary}</MarkdownWrapper>
          </div>
        </Panel>
        {this.getTechindicatorDetails()}
      </>
    );
  }
}
