import cn from "classnames";
import PubSub from "pubsub-js";
import React from "react";
import { GQLReport, GQLReportTrack, GQLReportView, GQLScore } from "../../../schemas/schema";
import { ExpandableComponent } from "../../components";
import { ExpandedTrackRow } from "./ExpandedTrackRow";
import { cciaQuery } from "../../../lib/ccia/ccia";

interface Props {
  obj: GQLReportTrack | GQLReportView
  report: GQLReport
  index: number
  score: GQLScore | null
}

export class ReportTrackRow extends ExpandableComponent<Props> {
  ExpandedComponent = ExpandedTrackRow as any;

  constructor(props) {
    super(props);
    PubSub.subscribe("panel.scrollToTrack", (e, rtId) => {
      if (rtId == props.obj.id) {
        const el = this._getDOMElement();
        // @ts-expect-error     offsetTop does exist in type Element
        window.setTimeout(() => window.scroll({ top: this._getDOMElement()!.offsetTop, behavior: "smooth" }), 0);
        this.setState({ expanded: true });
      }
    });
  }

  render() {
    const { obj, report, score, index } = this.props;
    const { expanded } = this.state;
    const { ExpandedComponent } = this;

    return cciaQuery(report.id, { withCharts: true }, (ccia) =>
      <>
        <div className={cn("full-row", `full-row-table track_${obj.id}`)} onClick={this.toggleExpanded}>
          <p>{index + 1}</p>
          <p>{obj.name}</p>
          <p className="score">
            Track Score:
            <span className="outline dark-gray">{score?.value}</span>
          </p>
          <div className={cn("arrow hide", { up: expanded })} />
        </div>
        {expanded && <ExpandedComponent obj={obj} report={report} score={score} ccia={ccia} />}
      </>
    );
  }
}
