import React from "react";
import LegendTooltip from "../../LegendTooltip";

export class ProjectHistory extends React.Component<{ dashboard: any }> {
  componentDidMount() {
    const { dashboard } = this.props;
    window.ChartBuilders.ScatterChartTimeBuilder.build(
      "#scatter-chart-blue",
      dashboard.scatter_time_chart.dataset.map(JSON.parse),
      JSON.parse(dashboard.scatter_time_chart.domain)
    );
  }

  render() {
    return (
      <section className="panel panel-project-history">
        <header>
          <div className="project-history" />
          <div className="text-block">
            <h4>Review History</h4>
          </div>
          <div className="tooltip-hover">
            <LegendTooltip
              text={
                <div className={"tooltip-div"}>
                  <h5>Review History</h5>
                  <p>
                    This chart displays a recent history of all reports generated. Confidence scores and other
                    information is provided. Click on any score to view the underlying report.
                  </p>
                </div>
              }
            />
          </div>
        </header>
        <div className="diagram-block" id="scatter-chart-blue" />
      </section>
    );
  }
}
