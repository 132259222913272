import gql from "graphql-tag";
import React from "react";
import { gqlQuerySilent } from "../common/lib";

export default class SuperAdminClientsSidebar extends React.Component {
  private readonly query = gql`
    {
      clients {
        id
        name
        projects {
          id
          company {
            id
            name
          }
          latest_report {
            id
          }
        }
      }
    }
  `;

  private currentClientId = "";

  componentDidMount() {
    const match = window.location.pathname.match(/\/clients\/([0-9]+)/);
    this.currentClientId = match ? match[1] : "";
  }

  itemClass(client: any) {
    if (client.id !== this.currentClientId) return "company";
    return "company active";
  }

  clientLink(client: any, index: number) {
    const className = `company-title ${index === 0 ? "active" : ""}`;
    const href = `/clients/${client.id}`;
    return (
      <a href={href} className={className}>
        {client.name}
      </a>
    );
  }

  projectItem(client: any, project: any) {
    const href = `/clients/${client.id}/reports/${project.latest_report.id}`;
    return (
      <li key={project.id}>
        <a href={href}>{project.company.name}</a>
      </li>
    );
  }

  expandDropdown(e) {
    const navItem = $(e.currentTarget).closest(".company");
    if (navItem.hasClass("active")) {
      navItem.removeClass("active");
    } else {
      $(".sidenav .company").removeClass("active");
      navItem.addClass("active");
    }
  }

  render() {
    return gqlQuerySilent(this.query, {}, ({ clients }) => {
      clients = clients.slice().sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      return (
        <ul className="sidenav">
          {clients.map((client, index) => (
            <li className={this.itemClass(client)} key={client.id}>
              <div className="company-wrap">
                {this.clientLink(client, index)}
                <div className="nav-arrow" onClick={this.expandDropdown}></div>
              </div>
              <ul className="accordian">
                {client.projects
                  .filter((project) => project.latest_report)
                  .map((project) => this.projectItem(client, project))}
              </ul>
            </li>
          ))}
        </ul>
      );
    });
  }
}
