import React from "react";
import LegendTooltip from "../../LegendTooltip";

export class DonutChart extends React.Component<{ dashboard: any }> {
  componentDidMount() {
    const { dashboard } = this.props;
    window.ChartBuilders.DonutChartBuilder.build(
      "#donut-chart",
      dashboard.donut_chart_builder.dataset.map(JSON.parse),
      dashboard.donut_chart_builder.domain
    );
  }

  render() {
    return (
      <section className={"panel panel-donut-chart"} id={"donut-chart"}>
        <div className="tooltip-hover">
          <LegendTooltip
            text={
              <div className={"tooltip-div"}>
                <h5>Portfolio Confidence Distribution</h5>
                <p>
                  This chart provides the distribution of the most report Confidence Scores for each company in your
                  portfolio, mapped to red, yellow or green as applicable.
                </p>
              </div>
            }
          />
        </div>
      </section>
    );
  }
}
