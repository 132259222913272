import "../sentry-error-handler";
import { loadReactContext } from "../react-context";
import LegendTooltip from "../client/LegendTooltip.tsx";
import ClientReport from "../client/ClientReport";
import SuperAdminClientsSidebar from "../admin/SuperAdminClientsSidebar";
import { Wizard2FAWrapper } from "../client/Wizard2FAWrapper";
import { ClientDashboard } from "../client/ClientDashboard";
import initIcons from "../init-fabric-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ClientDocuments } from "../client/ClientDocuments";
import PromptsController from "../client/PromptsController";
import "./client_legacy";
import "@/assets/stylesheets/client_application.scss";
import "@/admin/scoring_colors.scss";
import { NormalDistributionGraph } from "../lib/graphs";

initIcons("fas");
library.add(fas);

loadReactContext({
  ClientReport,
  LegendTooltip,
  Wizard2FAWrapper,
  PromptsController,
  ClientDashboard,
  ClientDocuments,
  SuperAdminClientsSidebar,
  NormalDistributionGraph
});
