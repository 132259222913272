import { ApolloProvider } from "@apollo/client";
import gql from "graphql-tag";
import * as React from "react";
import Wizard2FA from "../common/components/Wizard2FA";
import { gqlMutation, gqlQuerySilent } from "../common/lib";

export class Wizard2FAWrapper extends React.Component {
  state = {
    show: false
  };

  query = gql`
    query user_query {
      current_user {
        id
        m2fa_method
      }
    }
  `;

  toggleMutation = gql`
    mutation user_settings($m2fa_method: String, $code: String) {
      update_settings(m2fa_method: $m2fa_method, m2fa_token: $code) {
        id
        m2fa_method
      }
    }
  `;

  mutate = (mutateFunc, code = "") => {
    return mutateFunc({
      variables: { m2fa_method: "", code }
    });
  };

  openWizard = () => this.setState({ show: true });

  render() {
    return (
      <ApolloProvider client={window.apolloClient}>
        {gqlQuerySilent(this.query, {}, ({ current_user }) => {
          const is_2fa_enabled = current_user.m2fa_method !== "";
          return (
            <>
              <div className="input-block text-input m2fa-block">
                <label>Two Factor Authentication (2FA):</label>
                <div>
                  <a className={`button ${is_2fa_enabled ? "green" : "red"}`}>
                    {is_2fa_enabled ? "Enabled" : "Disabled"}
                  </a>
                  {gqlMutation(this.toggleMutation, (mutateFunc) => (
                    <a
                      className="link"
                      onClick={() => {
                        is_2fa_enabled
                          ? window.require_2fa((code) => this.mutate(mutateFunc, code))
                          : this.openWizard();
                      }}
                    >
                      {is_2fa_enabled ? "Disable" : "Enable"}
                    </a>
                  ))}
                </div>
              </div>
              {this.state.show && <Wizard2FA onClose={() => this.setState({ show: false })} />}
            </>
          );
        })}
      </ApolloProvider>
    );
  }
}
