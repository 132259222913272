import React from "react";
import { GQLReport, GQLReportView } from "../../../schemas/schema";

interface IProps {
  views: GQLReportView[]
  report: GQLReport
}

export class ViewSummary extends React.Component<IProps> {
  renderView(rv) {
    return (
      <div className="bar">
        <p className="text" style={{ textAlign: "left" }}>
          {rv.name}
        </p>
        <span className="progress">
          <div className={"measure view-measure"} style={{ width: (rv.score ? rv.score.value : 0) + "%" }} />
          {!rv.score && <p className="bar-text">Not Assessed</p>}
        </span>
        {rv.score && <div className={"square outline dark-gray"}>{rv.score.value}</div>}
      </div>
    );
  }

  render() {
    const all_views = this.props.views;
    return (
      <section className="module full mt2 mb2">
        <header className="small">
          <h6>Views Summary</h6>
        </header>
        <div className="diagram-block" id="bullet-chart">
          <div className="bar">
            <div className={"progress-markers"} style={{ backgroundColor: "white" }}>
              {[0, 25, 50, 75, 100].map((perc) => (
                <span
                  style={{
                    marginLeft: perc == 0 ? "" : "-1em",
                    position: "absolute",
                    left: `${perc}%`
                  }}
                >
                  {perc}
                </span>
              ))}
            </div>
          </div>
          {all_views.map((rv) => this.renderView(rv))}
        </div>
      </section>
    );
  }
}
