export * from "./TechnicalIndicators";
export * from "./ViewIndicators";

$(() => {
  $(".report-expand-all-btn").click((e) => {
    // @ts-expect-error
    const expanded = e.currentTarget.innerText == "EXPAND ALL";
    // @ts-expect-error
    window.expanded = expanded;
    PubSub.publish("client_report.expand-all", expanded);
  });
});
