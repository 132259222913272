import React from "react";
import { firstBy } from "thenby";
import { GQLReportTopic } from "../../../schemas/schema";
import { ReportIndicatorNotes } from "./ReportIndicatorNotes";

interface Props {
  topics: GQLReportTopic[]
}

export const TechindicatorTable = ({ topics }: Props) => (
  <table className="techindicator-table">
    <tbody>
      <tr className="title-row">
        <th>Topic</th>
        <th>Techindicator™</th>
        <th>Business Relevance</th>
        <th>Maturity Level</th>
        <th></th>
      </tr>
      {[...topics].sort(firstBy(topic => topic.sort_order)).map((topic) => (
        <React.Fragment key={topic.id}>
          <tr>
            <td className="trackindicator-tier-name" colSpan={5} data-testid="topic-name">
              {topic.name!.toUpperCase()}
            </td>
          </tr>
          {topic.report_indicators!
            .filter((report_indicator) => report_indicator.visible !== "EXCLUDED")
            .map((report_indicator, index) => (
            <ReportIndicatorNotes ri={report_indicator} i={index} key={index} />
            ))}
        </React.Fragment>
      ))}
    </tbody>
  </table>
);
