import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTooltip } from "../../../common/lib";
import { GQLReport, GQLTrack, GQLTrackCcia } from "../../../schemas/schema";
import LegendTooltip from "../../LegendTooltip";
import { isNoIndustryData } from "../../utils";

interface IProps {
  report: GQLReport
  tracks: GQLTrack[]
  ccia_tracks: GQLTrackCcia[]
}

export class TrackSummary extends React.Component<IProps> {
  scrollToPanel(rtId) {
    PubSub.publish("panel.scrollToTrack", rtId);
  }

  renderTrack(rt) {
    let score: any = null;
    let [laggards, leaders, mean] = [0, 0, 0];
    if (rt.track) {
      laggards = this.props.ccia_tracks.find((x) => x.rtid == rt.id)?.laggards?.value ?? 0;
      leaders = this.props.ccia_tracks.find((x) => x.rtid == rt.id)?.leaders?.value ?? 0;
      mean = this.props.ccia_tracks.find((x) => x.rtid == rt.id)?.mean?.value ?? 0;
      score = this.props.ccia_tracks.find((x) => x.rtid == rt.id)?.score ?? null;
    }

    const showProgress = score && !!mean;
    const noIndustryData = isNoIndustryData(this.props.report, rt.id, this.props.ccia_tracks);

    return (
      <div key={rt.id} className="bar" onClick={() => this.scrollToPanel(rt.id)}>
        <p className="text" style={{ textAlign: "left" }}>
          {rt.name} {noIndustryData ? "*" : ""}
        </p>
        <span className="progress">
          {showProgress && (
            <>
              <span className="laggards-icon caret" style={{ left: laggards + "%" }}>
                {withTooltip(`Industry lagging ${Math.round(laggards)}`, <FontAwesomeIcon icon={faCaretLeft} />)}
              </span>
              <span className="leaders-icon caret" style={{ left: leaders + "%" }}>
                {withTooltip(`Industry leading ${Math.round(leaders)}`, <FontAwesomeIcon icon={faCaretRight} />)}
              </span>
              <span className="mean-icon caret" style={{ left: mean + "%" }}>
                {withTooltip(
                  `Industry average ${Math.round(mean)}`,
                  <>
                    <FontAwesomeIcon icon={faCaretLeft} />
                    <FontAwesomeIcon icon={faCaretRight} />
                  </>
                )}
              </span>
            </>
          )}
          <div
            className={`measure ${this.colorForScore(score, showProgress)}`}
            style={{ width: (score ? score.value : 0) + "%" }}
          />
          {!score && <p className="bar-text">Not Assessed</p>}
        </span>
        {score && <div className={`square outline ${this.colorForScore(score, showProgress)}`}>{score.value}</div>}
      </div>
    );
  }

  render() {
    const { report } = this.props;
    const legend = (
      <>
        This table summarizes the company's CCI scores for each track that was evaluated. Below, and in the
        accompanying chart, this score is compared to other companies that Corsis has evaluated within the same industry
        group. Scores are assigned colors per the legend below:
        <ul>
          <li>84th to 99th: (Industry leading): green</li>
          <li>75th to 83rd: light-green</li>
          <li>25th to 74th: yellow</li>
          <li>17th to 24th: orange.</li>
          <li>1st to 16th (Industry lagging): red</li>
        </ul>
      </>
    );
    const includingInsufficientTrack = report.report_tracks!.reduce(
      (result, { id }) => result || isNoIndustryData(report, id, this.props.ccia_tracks),
      false
    );

    return (
      <section className="module full mt2 mb2">
        <header className="small">
          <h6>Track Summary</h6>
          <div className="tooltip-hover">
            <LegendTooltip text={legend} colors={["red", "orange", "yellow", "lightgreen", "green"]} />
          </div>
        </header>
        <div className="diagram-block" id="bullet-chart">
          <div className="bar">
            <div className="progress-markers" style={{ backgroundColor: "white" }}>
              {[0, 25, 50, 75, 100].map((perc) => (
                <span
                  key={perc}
                  style={{
                    marginLeft: perc == 0 ? "" : "-1em",
                    position: "absolute",
                    left: `${perc}%`
                  }}
                >
                  {perc}
                </span>
              ))}
            </div>
          </div>
          {report.report_tracks!.map((rt) => this.renderTrack(rt))}
          {includingInsufficientTrack && (
            <div className="bar" style={{ fontSize: 12 }}>
              *Insufficient industry data for comparative analysis
            </div>
          )}
        </div>
      </section>
    );
  }

  colorForScore(score, showProgress) {
    if (!showProgress) {
      return "dark-gray";
    } else if (!score) {
      return "N/A";
    }
    return score.color;
  }
}
