import React from "react";
import { gqlQuery } from "../../common/lib";
import { TechnicalIndicators, ViewIndicators } from "../TechnicalIndicators";
import {
  AdminPanel,
  ExecutiveSummary,
  Header,
  ProjectScope,
  ReportOverall,
  TrackSummary,
  ViewSummary
} from "./components";

import { ReportShowQuery } from "gql/client_report.graphql";
import { cciaQuery } from "../../lib/ccia/ccia";

export class ClientReport extends React.Component<{ reportId: number, admin: boolean }> {
  private readonly query = ReportShowQuery;

  render() {
    const includedOnly = (x) => x.online_report;
    const hasSummary = (x) => x.summary;

    return gqlQuery(this.query, { id: this.props.reportId }, (res) => (
      <>
        <Header report={res.report} />
        {this.props.admin && <AdminPanel report={res.report} />}
        <ProjectScope report={res.report} tracks={res.tracks} tiers={res.tiers} />
        {cciaQuery(res.report.id, { withCharts: true }, (ccia) => (
          <>
            <ReportOverall report={res.report} ccia={ccia} />
            <TrackSummary report={res.report} tracks={res.tracks} ccia_tracks={ccia.tracks!} />
            {res.report.deliverable_format === "corsis" && <ExecutiveSummary report={res.report} />}
            <TechnicalIndicators report={res.report} ccia_tracks={ccia.tracks!} />
          </>
        ))}
        {!!res.report.report_views.filter(includedOnly).filter(hasSummary).length && (
          <>
            <ViewSummary report={res.report} views={res.report.report_views.filter(includedOnly)} />
            <ViewIndicators report={res.report} views={res.report.report_views.filter(includedOnly)} />
          </>
        )}
      </>
    ));
  }
}
