import React from "react";
import { ExpandableComponent } from "./ExpandableComponent";

export class Panel extends ExpandableComponent<{ title: string, helpComponent?: JSX.Element, className?: string }> {
  render() {
    const { title, helpComponent } = this.props;
    return (
      <section className={`module full client-panel ${this.props.className || ""}`}>
        <header className="small full-row-table" onClick={this.toggleExpanded}>
          <h6 style={{ lineHeight: "unset" }}>
            {title}
            {helpComponent || ""}
          </h6>
          <div className={["arrow hide", this.state.expanded ? "up" : ""].join(" ")} />
        </header>
        {this.state.expanded && <div className="module-body full-row-body active">{this.props.children}</div>}
      </section>
    );
  }
}
