import { ClientDashboardQuery } from "gql/client_dashboard_query.graphql";
import React from "react";
import { gqlQuery } from "../../common/lib";
import { ClientDashboardInner } from "./ClientDashboardInner";

export class ClientDashboard extends React.Component {
  query = ClientDashboardQuery;
  render() {
    return gqlQuery(this.query, ({ current_user, current_client, dashboard }) => {
      const overview = JSON.parse(dashboard.overview);
      return (
        <ClientDashboardInner
          allowed_projects={current_user.allowed_projects.map((p) => p.id)}
          current_client={current_client}
          dashboard={dashboard}
          overview={overview}
        />
      );
    });
  }
}
