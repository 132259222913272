import { Dropdown } from "office-ui-fabric-react";
import React from "react";
import { showIf } from "../../common/lib";
import { GQLClient } from "../../schemas/schema";
import LegendTooltip from "../LegendTooltip";
import { DashboardProjectTable, DonutChart, ProjectHistory } from "./components";

export class ClientDashboardInner extends React.Component<{
  current_client: GQLClient
  allowed_projects: ID[]
  overview: any
  dashboard: any
}> {
  state = {
    chart_type: "industry",
    init: false
  };

  componentDidMount() {
    const { scatter_chart, scatter_chart_industry } = this.props.dashboard;
    window.ChartBuilders.ScatterChartBuilder.build(
      "#scatter-chart-industry",
      scatter_chart_industry.dataset.map(JSON.parse),
      scatter_chart_industry.domain,
      { filter: true }
    );
    window.ChartBuilders.ScatterChartBuilder.build(
      "#scatter-chart",
      scatter_chart.dataset.map(JSON.parse),
      scatter_chart.domain,
      { filter: true }
    );
    setTimeout(() => this.setState({ init: true }), 1);
  }

  render() {
    const allowed_projects = new Set(this.props.allowed_projects);
    const { current_client, overview, dashboard } = this.props;
    return (
      <div className={"page dashboard dashboard-enterprise"}>
        <div className="top-toolbar">
        </div>
        <div className="section-sub-header">
          <h2>{current_client.name}</h2>{" "}
        </div>
        <section className="module mt3 mb3">
          <section className="panel panel-overview">
            <header>
              <div className="overview-icon" />
              <div className="text-block">
                <h4>Overview</h4>
                <p>Important Updates at a Glance</p>
              </div>
            </header>
            <div className="diagram-block">
              {this.square(
                "Portfolio Confidence",
                "Represents the average Confidence Score of all Projects in your portfolio",
                overview.portfolio_confidence,
                overview.portfolio_confidence_color
              )}
              {this.square(
                "Portfolio Movement",
                "Indicates positive or negative movement in the Portfolio Confidence score during the current quarter",
                overview.portfolio_movement,
                overview.portfolio_movement_color,
                { description: "This quarter" }
              )}
              {this.square("Companies Measured", "", overview.clients_measured, "gray")}
              {this.square(
                "New Companies",
                "Indicates positive or negative movement in the Portfolio Confidence score during the current quarter",
                overview.new_clients,
                "gray",
                { description: "This quarter" }
              )}
              {this.square(
                "Highest Score",
                "Indicates the highest overall score in your portfolio",
                overview.highest_score,
                overview.highest_score_color,
                { description: overview.highest_score_name }
              )}
              {this.square(
                "Lowest Score",
                "Indicates the lowest overall score in your portfolio",
                overview.lowest_score,
                overview.lowest_score_color,
                { description: overview.lowest_score_name }
              )}
            </div>
          </section>
          <section className="panel panel-performance">
            <header>
              <div className="performance-icon" />
              <div className="text-block">
                <h4>Performance Index</h4>
                <p>Corsis Confidence Index&#174; Score vs. Gross Revenue ($ Millions)</p>
              </div>
              <div className="tooltip-hover">
                <LegendTooltip
                  text={
                    <div className={"tooltip-div"}>
                      <h5>Normal Distribution</h5>
                      <p>
                        The chart presents the company's CCI scores in relation to the scores of other companies that were
                        evaluated within the same industry group. The 'Leaders' and 'Laggards' scores are calculated as
                        one standard deviation above and below the average, respectively. In most cases, this can be
                        interpreted as a score that is higher (or lower) than 85 percent of all companies measured
                      </p>
                    </div>
                  }
                />
              </div>
              <div className="dropdown-holder">
                <Dropdown
                  options={[
                    {
                      key: "industry",
                      text: "Industry"
                    },
                    {
                      key: "portfolio",
                      text: "Portfolio"
                    }
                  ]}
                  onChange={(e, v) => this.setState({ chart_type: v.key })}
                  selectedKey={this.state.chart_type}
                />
              </div>
            </header>
            <div
              {...showIf(!this.state.init || this.state.chart_type == "portfolio")}
              className="scatter-chart diagram-block diagram-block-chart"
              id="scatter-chart"
            />
            <div
              {...showIf(!this.state.init || this.state.chart_type == "industry")}
              className="scatter-chart diagram-block diagram-block-chart"
              id="scatter-chart-industry"
            />
          </section>
        </section>
        <section className="module mt3 mb3">
          <ProjectHistory dashboard={dashboard} />
          <DonutChart dashboard={dashboard} />
        </section>
        <section className="module full mt3 mb3">
          <header className="white">
            <div className="arrow-gear-icon" />
            <div className="text-block">
              <h4>Overview</h4>
              <p>Important Updates at a Glance</p>
            </div>
            <div className="tooltip-hover">
              <LegendTooltip
                text={
                  <div className={"tooltip-div"}>
                    <h5>Projects</h5>
                    <p>
                      A summary of all companies in your portfolio. For companies that have multiple reports, the date
                      and Confidence Score is shown for the most recent
                    </p>
                  </div>
                }
              />
            </div>
          </header>
          <DashboardProjectTable projects={dashboard.projects.filter(x => allowed_projects.has(x.id))} />
        </section>
      </div>
    );
  }

  private square(name: string, helpText: string, value: number, color: string, options = {} as any) {
    return (
      <div className="square-block">
        <div className="square">
          <h1 className={`vert-center outline ${color}`} data-title={helpText} data-toggle="tooltip" title={helpText}>
            {value}
            {options.percentage && "%"}
          </h1>
        </div>
        <p>{name}</p>
        {options.description && <p className={"outline gray"}>{options.description}</p>}
      </div>
    );
  }
}
