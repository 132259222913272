import React from "react";
import { cciaQuery } from "../../../lib/ccia/ccia";
import { NormalDistributionGraph } from "../../../lib/graphs";
import { GQLCcia, GQLReport, GQLScore } from "../../../schemas/schema";
import LegendTooltip from "../../LegendTooltip";

interface IProps {
  report: GQLReport
  ccia: GQLCcia
}

export class ReportOverall extends React.Component<IProps> {
  render() {
    const legend = (
      <>
        The Overall Score is a weighted combination of the scores from the various assessment tracks. The Overall Score
        provides a general measure of the maturity of the company's technology operations. Below, and in the
        accompanying chart, this score is compared to other companies that Corsis has evaluated within the same industry
        group. Scores are assigned colors per the legend below:
        <ul>
          <li>84th to 99th: (Industry leading): green</li>
          <li>75th to 83rd: light-green</li>
          <li>25th to 74th: yellow</li>
          <li>17th to 24th: orange.</li>
          <li>1st to 16th (Industry lagging): red</li>
        </ul>
      </>
    );
    const { report, ccia } = this.props;
    return (
      <section className="report-overall module mt3 mb3">
        <section className="panel panel-overall">
          <header>
            <div className="text-block">
              <h4>Overall</h4>
            </div>
            <div className="tooltip-hover">
              <LegendTooltip text={legend} colors={["red", "orange", "yellow", "lightgreen", "green"]} />
            </div>
          </header>
          {cciaQuery(report.id, { withCharts: true }, (ccia) => (
            <>
              <div className="diagram-wrap">
                <div className="diagram-block">
                  <span>Your Score</span>
                  {this.output_score(ccia.score!, true)}
                </div>
                <div className="diagram-block">
                  <span>Average</span>
                  {this.output_score(ccia.mean!)}
                </div>
                <div className="diagram-block">
                  <span>Leaders</span>
                  {this.output_score(ccia.leaders!)}
                </div>
                <div className="diagram-block">
                  <span>Laggards</span>
                  {this.output_score(ccia.laggards!)}
                </div>
              </div>
            </>
          ))}
        </section>
        <section className="panel panel-line">
          <header>
            <div className="text-block">
              <h4>Normal Distribution</h4>
            </div>
            <div className="tooltip-hover">
              <LegendTooltip
                text={
                  <div className={"tooltip-div"}>
                    <h5>Normal Distribution</h5>
                    <p>
                      The chart presents the company's Corsis Confidence Index&#174; (CCI) score in relation to the
                      scores of other companies that were evaluated within the same industry group. The 'Leaders' and
                      'Laggards' scores are calculated as one standard deviation above and below the average,
                      respectively. In most cases, this can be interpreted as a score that is higher (or lower) than 85
                      percent of all companies measured
                    </p>
                  </div>
                }
              />
            </div>
          </header>
          <div>
            <NormalDistributionGraph
              x_values={ccia.normal_distribution_chart?.x_values || []}
              y_values={ccia.normal_distribution_chart?.y_values || []}
              mean_value={ccia.normal_distribution_chart?.mean_value}
              laggards_value={ccia.normal_distribution_chart?.laggards_value}
              leaders_value={ccia.normal_distribution_chart?.leaders_value}
              subject_name={report.project_alias_for_graph!}
              subject_score={ccia.score!.value!}
            />
          </div>
        </section>
      </section>
    );
  }

  private output_score(score: GQLScore, use_color = false) {
    let color = "gray";
    if (use_color) {
      color = score.color!;
    }
    return (
      <div className={`square ${color}`}>
        <h1>{Math.round(score.value!)}</h1>
      </div>
    );
  }
}
