import { ClientDocumentsQuery } from "gql/client_documents.graphql";
import { Checkbox, IconButton, TooltipHost } from "office-ui-fabric-react";
import React from "react";
import { DateFormats, friendlyDate } from "../admin/utils";
import { gqlQuery } from "../common/lib";
import { copyToClipboard } from "../common/utils";

interface IProps {
  project_id: string
}

export const ClientDocuments = ({ project_id: projectId }: IProps) =>
  gqlQuery(ClientDocumentsQuery, { projectId }, ({ project, file_uploads }) => (
    <div className={"page client-documents"}>
      <section className="module full mt2 mb2">
        <header>
          <div className="overview-icon" />
          <div className="text-block">
            <h4>{project.name}</h4>
            <p>Project Documents</p>
          </div>
        </header>
        <table className="documents-table">
          <tbody>
            <tr className="title-row">
              <th></th>
              <th className="report-name">Associated Report</th>
              <th>File Type</th>
              <th>Primary</th>
              <th>File Name</th>
              <th>Description</th>
              <th>Create Date</th>
              <th>Actions</th>
            </tr>
            {file_uploads.map((file, index) => (
              <tr key={index}>
                <td className="checkbox"></td>
                <td className="long report-name">{file.report.name}</td>
                <td>{file.file_type_verbose}</td>
                <td className="checkbox">
                  {file.is_primary === true ? <Checkbox disabled={true} checked={true} /> : null}
                </td>
                <td data-testid="filename">{file.filename}</td>
                <td>{file.description}</td>
                <td className="data">{friendlyDate(file.created_at, DateFormats.Short_Date)}</td>
                <td className="flex icons">
                  <TooltipHost content="Download">
                    <IconButton data-testid="list-download" href={file.url} iconProps={{ iconName: "download" }} />
                  </TooltipHost>
                  <TooltipHost content="Copy Link">
                    <IconButton
                      data-testid="list-copy"
                      onClick={() => copyToClipboard(file.url)}
                      iconProps={{ iconName: "copy" }}
                    />
                  </TooltipHost>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  ));
