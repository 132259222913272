import { ITooltipHostStyles, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import React from "react";

interface IProps {
  text: string | JSX.Element
  colors?: string[]
}

export default class LegendTooltip extends React.Component<IProps> {
  state = {
    expanded: false
  };

  collapse = () => this.setState({ expanded: false });
  expand = () => this.setState({ expanded: true });
  render() {
    const { text, colors } = this.props;
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block", fontSize: "12pt" } };

    const content = (
      <>
        {colors && (
          <div className={`color-legend n${colors.length}`}>
            {colors.map((color) => (
              <div key={color} className={color} />
            ))}
          </div>
        )}
        <div style={{ marginTop: colors ? "1em" : "0" }}>{text}</div>
      </>
    );

    return (
      <TooltipHost content={content} delay={TooltipDelay.zero} styles={hostStyles}>
        <div className="question">
          <div className="question-bubble" />
        </div>
      </TooltipHost>
    );
  }
}
