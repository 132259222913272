import React from "react";
import { GQLReportTrack } from "../../../schemas/schema";
import { ReportIndicatorNotes } from "./ReportIndicatorNotes";

interface Props {
  tracks: GQLReportTrack[]
}

export const TechindicatorTracksTable = ({ tracks }: Props) => (
  <table className="techindicator-table">
    <tbody>
      <tr className="title-row">
        <th>Track</th>
        <th>Techindicator™</th>
        <th>Business Relevance</th>
        <th>Maturity Level</th>
        <th></th>
      </tr>
      {tracks.map((track) => (
        <React.Fragment key={track.id}>
          <tr>
            <td className="trackindicator-tier-name" colSpan={5}>
              {track.name.toUpperCase()}
            </td>
          </tr>
          {track.report_indicators!.map((ri, i) => (
            <ReportIndicatorNotes ri={ri} i={i} key={i} />
          ))}
        </React.Fragment>
      ))}
    </tbody>
  </table>
);
