import React from "react";
import { GQLReport } from "../../../schemas/schema";
import { Panel } from "../../components";

export class AdminPanel extends React.Component<{ report: GQLReport }> {
  render() {
    const { report } = this.props;
    return (
      <Panel className={"panel-danger"} title={"Admins"}>
        <table className="report-detail-table">
          <tbody>
            <tr>
              <td>Company</td>
              <td>{report.company!.name}</td>
            </tr>
            <tr>
              <td>Tags</td>
              <td>{report.company!.default_industry_tag!.name}</td>
            </tr>
            <tr>
              <td>Industry Group</td>
              <td>{report.company!.default_industry_group!.name}</td>
            </tr>
          </tbody>
        </table>
      </Panel>
    );
  }
}
