import React from "react";
import { NormalDistributionGraph } from "../../../lib/graphs";
import { MarkdownWrapper } from "../../../reports/common";
import { GQLCcia, GQLReport, GQLReportTrack, GQLReportView, GQLScore } from "../../../schemas/schema";
import { Panel, ReportKeyListTable } from "../../components";
import LegendTooltip from "../../LegendTooltip";
import { techDetailsHelpText } from "./constants";
import { TechindicatorTable } from "./TechindicatorTable";

interface Props {
  obj: GQLReportTrack | GQLReportView
  report: GQLReport
  score: GQLScore
  ccia: GQLCcia
}

export class ExpandedTrackRow extends React.Component<Props> {
  renderSummary() {
    const { obj, report, ccia } = this.props;
    const rt = obj as GQLReportTrack;
    const thisRt = (x) => x.report_track.id == obj.id;
    const ccia_track = ccia.tracks!.find((x) => x.rtid == obj.id)!;
    const cciaSummaryOutline = ccia_track.summary_outline;
    const { normal_distribution_chart } = ccia_track;

    return (
      <>
        <div className="track-chart-panel">
          <div className="normal-dist-chart normaldist-chart">
            <NormalDistributionGraph
              x_values={normal_distribution_chart.x_values || []}
              y_values={normal_distribution_chart.y_values}
              laggards_value={normal_distribution_chart.laggards_value}
              leaders_value={normal_distribution_chart.leaders_value}
              mean_value={normal_distribution_chart.mean_value}
              subject_name={report?.project_alias_for_graph || "Project Alias"}
              subject_score={ccia_track.score!.value!}
              hide_y_label
            />
          </div>
          <div className="track-score-details">
            <div className="markdown">
              <MarkdownWrapper>{cciaSummaryOutline}</MarkdownWrapper>
              <MarkdownWrapper>{rt.track_context}</MarkdownWrapper>
            </div>
          </div>
        </div>
        {report.deliverable_format === "corsis" && (
          <>
            <Panel title={`Track Summary - ${obj.name}`}>
              <div className="panel-text">
                <MarkdownWrapper>{rt.track_summary}</MarkdownWrapper>
              </div>
            </Panel>
            <Panel title={`Key Strengths - ${obj.name}`}>
              <ReportKeyListTable title={"Strength"} items={report.key_strengths!.filter(thisRt)} />
            </Panel>
            <Panel title={`Recommendations - ${obj.name}`}>
              <ReportKeyListTable title={"Recommendations"} items={report.recommendations!.filter(thisRt)} withImpact />
            </Panel>
          </>
        )}
        {this.getTechindicatorDetails()}
      </>
    );
  }

  protected getTechindicatorDetails() {
    const { obj } = this.props;

    return (
      <Panel
        title="Techindicator™ Details"
        helpComponent={
          <LegendTooltip text={techDetailsHelpText} colors={["red", "orange", "yellow", "lightgreen", "green"]} />
        }
      >
        <TechindicatorTable topics={obj.topics!} />
      </Panel>
    );
  }

  render() {
    return <div className="full-row-body active">{this.renderSummary()}</div>;
  }
}
