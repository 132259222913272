import { ApolloProvider } from "@apollo/client";
import React from "react";
import { ClientReport } from "./ClientReport";

export default class ClientReportWrapper extends React.Component {
  constructor(props) {
    super(props);
    window.onpopstate = () => window.location.reload();
  }

  render() {
    // @ts-expect-error
    const Component = <ClientReport {...this.props} />;
    return <ApolloProvider client={window.apolloClient}>{Component}</ApolloProvider>;
  }
}
