import { GQLCcia, GQLReport, GQLTrackCcia } from "../schemas/schema";

export const isNoIndustryData = (report: GQLReport, rtid: string, ccia: GQLCcia | GQLTrackCcia[]) => {
  const cciaTracks = Array.isArray(ccia) ? ccia : ccia.tracks!;
  const track = cciaTracks.find((x) => x.rtid === rtid);
  return !!(
    !track ||
      track?.summary_outline?.includes("Comparative industry data is not available")
  );
};
