import React from "react";
import { GQLReport } from "../../../schemas/schema";

export class Header extends React.Component<{ report: GQLReport }> {
  render() {
    const { report } = this.props;
    return (
      <section className="module full mt2 mb2">
        <header>
          <div className="icon-dashboard-enterprise" />
          <div className="text-block">
            <h4>{report.project!.name}</h4>
            <p>{report.name}</p>
          </div>
          <div className="buttons">
            <a className="btn btn-primary " href={`/projects/${report.project!.id}/documents`}>
              <button className="button button-secondary">Project Documents</button>
            </a>
            {report.final_report_url && (
              <a className="btn btn-primary" target="_self" href={report.final_report_url} data-testid="download-button">
                <button className="button button-active small">Download</button>
              </a>
            )}
          </div>
        </header>
      </section>
    );
  }
}
