import React from "react";
import { MarkdownWrapper } from "../../../reports/common";
import { GQLReport } from "../../../schemas/schema";
import { Panel, ReportKeyListTable } from "../../components";

export class ExecutiveSummary extends React.Component<{ report: GQLReport }> {
  render() {
    const { report } = this.props;
    const highImpact = (x) => x.impact == "High";

    return (
      <Panel title={"Executive Summary"}>
        <div className="panel-text">
          <MarkdownWrapper>
            {report.executive_summary ? report.executive_summary : "No executive summary"}
          </MarkdownWrapper>
        </div>
        <Panel title={"Key Strengths - High Impact"}>
          <ReportKeyListTable title={"Strength"} items={report.key_strengths!.filter(highImpact)} withTrack />
        </Panel>
        <Panel title={"Recommendations - High Impact"}>
          <ReportKeyListTable
            title={"Recommendations"}
            items={report.recommendations!.filter(highImpact)}
            withTrack
            withImpact
          />
        </Panel>
      </Panel>
    );
  }
}
